import {
  DeliveryType,
  AutomationDeliveryDateType,
  DeliveryDetailsSourceType,
  ContactShortType,
} from "@types";
import { baseApi } from "@utils/apiService";
import { COMPANIES } from "@constants/api";
import { QueryKey } from "react-query";
import { AxiosPromise } from "axios";

export const automationAddressesValidate = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: DeliveryType }> =>
  baseApi
    .post(
      `${COMPANIES}/${queryKey[1]}/automations/${queryKey[2]}/deliveries/addresses/validate`
    )
    .then((res) => res.data);

type ValidationItemType = {
  contacts: ContactShortType[];
  missing_info_count: number;
  type: "contact_date_type" | "contact_address_type";
};

export const contactValidate = ({
  company_id,
  data,
}: {
  company_id: string;
  data: {
    contacts_filter_type: "contact_id" | "contact_tag";
    contacts: string[];
    contact_date_type?: AutomationDeliveryDateType;
    contact_address_type?: DeliveryDetailsSourceType;
  };
}): Promise<{
  total_count: 1;
  validations: ValidationItemType[];
}> =>
  baseApi
    .post(`${COMPANIES}/${company_id}/contacts/validate`, data, {
      params: {
        per_page: 500,
      },
    })
    .then((res) => res.data);
